import React, { useEffect, useState } from "react";
import { Create, SimpleForm, TextInput, DateInput, required, ReferenceInput, AutocompleteInput, Edit, useRecordContext } from "react-admin";
import { Card, CardContent, Grid, Typography, Paper, Snackbar, Alert, IconButton, useTheme } from "@mui/material";
import ContactPersonSection from "./ContactPersonSection.js";
import { useContacts } from "./ContactContext";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const CustomerForm = ({ isEditing, props, title, actions, toolbar }) => {
    const theme = useTheme();
    const { contacts, copySuccess, isSnackbarOpen, setSnackbarOpen } = useContacts();
    const [clipboardData, setClipboardData] = useState({
        name: "",
        street: "",
        postalCode: "",
        city: "",
    });
    const [contPersonClipboardData, setContPersonClipboardData] = useState([
        {
            salutation: "",
            firstName: "",
            lastName: "",
        },
    ]);

    useEffect(() => {
        const updatedClipboardData = contacts.map((contact) => ({
            salutation: contact.salutation,
            firstName: contact.firstName,
            lastName: contact.lastName,
        }));
        setContPersonClipboardData(updatedClipboardData);
    }, [contacts]);

    const handleClipboardDataChange = (e, identifier) => {
        setClipboardData((prevData) => {
            return { ...prevData, [identifier]: e.target.value };
        });
    };

    const copyToClipboard = () => {
        const textToCopy = `${clipboardData.name}\n${clipboardData.street}\n${clipboardData.postalCode} ${clipboardData.city}\n${contPersonClipboardData
            .map((data, index) => `${contPersonClipboardData.length > 1 ? index + 1 + ") " : ""}${data.salutation} ${data.firstName} ${data.lastName}`)
            .join("\n")}`;

        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setSnackbarOpen(true);
            },
            (err) => {
                console.error("Failed to copy: ", err);
            }
        );
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (copySuccess) {
        prompt(copySuccess);
    }
    if (isEditing) {
        return (
            <>
                <Edit title={title} actions={actions} {...props}>
                    <EditForm toolbar={toolbar}></EditForm>
                </Edit>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} sx={{ marginTop: "50px" }}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
                        Copied!
                    </Alert>
                </Snackbar>
            </>
        );
    } else {
        return (
            <>
                <Create title={title} actions={actions} {...props}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sm={12}>
                            <SimpleForm toolbar={toolbar}>
                                <Card
                                    variant="plain"
                                    sx={{
                                        [theme.breakpoints.down("sm")]: {
                                            marginBottom: "0px",
                                            paddingBottom: "0px",
                                        },
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" sx={{ mb: 3 }}>
                                            Kundeninformationen
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={9}>
                                                <TextInput source="company" label="Unternehmen" defaultValue="" validate={required()} fullWidth size="large" onChange={(e) => handleClipboardDataChange(e, "name")} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextInput source="id" label="ID" readOnly fullWidth size="large" helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextInput source="street" label="Straßenname und Nr." size="large" validate={required()} fullWidth defaultValue="" onChange={(e) => handleClipboardDataChange(e, "street")} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextInput source="postalCode" label="PLZ" defaultValue="" size="large" validate={required()} onChange={(e) => handleClipboardDataChange(e, "postalCode")} fullWidth helperText={false} />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextInput source="city" defaultValue="" label="Stadt" validate={required()} onChange={(e) => handleClipboardDataChange(e, "city")} fullWidth size="large" helperText={false} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="specialRequests" label="Spezielle Kundenwünsche" multiline rows={3} validate={required()} size="large" defaultValue="" helperText={false} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="notes" defaultValue="" label="Anmerkung" multiline rows={3} size="large" fullWidth validate={required()} helperText={false} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput source="constructionSite" defaultValue="" validate={required()} label="Baustelle" size="large" helperText={false} fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ReferenceInput
                                                    source="regionId"
                                                    reference="regions"
                                                    label="Region"
                                                    fullWidth
                                                    helperText={false}
                                                    size="large"
                                                    // validate={required()}
                                                >
                                                    <AutocompleteInput optionText="name" label="Region" validate={required()} />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ReferenceInput source="account_manager_id" label="Kundenbetreuer" reference="users">
                                                    <AutocompleteInput optionText="name" label="Kundenbetreuer" validate={required()} />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={12} md={4} marginTop={"0px"}>
                                                <DateInput source="customerSince" label="Kunde seit" defaultValue="" fullWidth size="large" validate={required()} helperText={false} />
                                            </Grid>
                                        </Grid>
                                        {contacts.map((contact, index) => (
                                            <ContactPersonSection key={index} index={index} contact={contact} />
                                        ))}
                                    </CardContent>
                                </Card>
                            </SimpleForm>
                        </Grid>
                        <Grid item xs={12} md={4} sm={12}>
                            <Grid item xs={12} md={7} sm={12}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        backgroundColor: "#EFF4FA",
                                        borderRadius: "6px",
                                        p: 2,
                                        marginTop: "90px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "left",
                                        [theme.breakpoints.down("sm")]: {
                                            p: 4,
                                            marginBottom: "150px",
                                            maxHeight: "calc(100vh - 50px)",
                                            height: "100%",
                                        },
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "top",
                                        }}
                                    >
                                        <Typography variant="p" sx={{ mb: 1, fontSize: "1rem", fontWeight: "600" }}>
                                            Kontaktdaten
                                        </Typography>
                                        <IconButton aria-label="delete" size="small" sx={{ marginTop: "-5px", color: "#3d3d3d", p: 1 }} onClick={copyToClipboard}>
                                            <ContentCopyRoundedIcon fontSize="small"> </ContentCopyRoundedIcon>
                                        </IconButton>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: "5px",
                                            fontSize: "0.9rem",
                                            fontWeight: "500",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography
                                            variant="p"
                                            sx={{
                                                mb: 1,
                                            }}
                                        >
                                            {clipboardData.name}
                                            <br></br>
                                            {clipboardData.street}
                                            <br></br>
                                            {clipboardData.postalCode + " " + clipboardData.city}
                                            <br></br>
                                            {contPersonClipboardData.map((data, index) => {
                                                return (
                                                    <div key={index} style={{ display: "flex", marginTop: "18px" }}>
                                                        <span
                                                            style={{
                                                                display: "block",
                                                                textTransform: "capitalize",
                                                                marginRight: "4px",
                                                            }}
                                                        >
                                                            {contPersonClipboardData.length > 1 && index + 1 + ")"} {data.salutation}{" "}
                                                        </span>
                                                        <div>
                                                            {data.firstName} {data.lastName}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Create>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} sx={{ marginTop: "50px" }}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
                        Copied!
                    </Alert>
                </Snackbar>
            </>
        );
    }
};

const EditForm = ({ toolbar }) => {
    const theme = useTheme(); // Access the theme here
    const contactRecord = useRecordContext();
    const { contacts, copySuccess, setSnackbarOpen, setContacts } = useContacts();
    useEffect(() => {
        if (contactRecord.contacts && contactRecord.contacts.length >= 1) {
            setContacts(contactRecord.contacts);
        }
    }, [contactRecord.contacts, setContacts]);

    const [clipboardData, setClipboardData] = useState({
        name: contactRecord.company || "",
        street: contactRecord.street || "",
        postalCode: contactRecord.postalCode || "",
        city: contactRecord.city || "",
    });
    const [contPersonClipboardData, setContPersonClipboardData] = useState([
        {
            salutation: "",
            firstName: "",
            lastName: "",
        },
    ]);

    useEffect(() => {
        const updatedClipboardData = contacts.map((contact) => ({
            salutation: contact.salutation,
            firstName: contact.firstName,
            lastName: contact.lastName,
        }));
        setContPersonClipboardData(updatedClipboardData);
    }, [contacts]);

    const handleClipboardDataChange = (e, identifier) => {
        setClipboardData((prevData) => {
            return { ...prevData, [identifier]: e.target.value };
        });
    };

    const copyToClipboard = () => {
        const textToCopy = `${clipboardData.name}\n${clipboardData.street}\n${clipboardData.postalCode} ${clipboardData.city}\n${contPersonClipboardData
            .map((data, index) => `${contPersonClipboardData.length > 1 ? index + 1 + ") " : ""}${data.salutation} ${data.firstName} ${data.lastName}`)
            .join("\n")}`;

        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setSnackbarOpen(true);
            },
            (err) => {
                console.error("Failed to copy: ", err);
            }
        );
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (copySuccess) {
        prompt(copySuccess);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} sm={12}>
                <SimpleForm toolbar={toolbar}>
                    <Card
                        variant="plain"
                        sx={{
                            [theme.breakpoints.down("sm")]: {
                                marginBottom: "0px",
                                paddingBottom: "0px",
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                Kundeninformationen
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <TextInput source="company" label="Unternehmen" validate={required()} fullWidth size="large" onChange={(e) => handleClipboardDataChange(e, "name")} helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextInput source="id" label="ID" readOnly fullWidth size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput source="street" label="Straßenname und Nr." size="large" fullWidth validate={required()} onChange={(e) => handleClipboardDataChange(e, "street")} helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextInput source="postalCode" label="PLZ" validate={required()} size="large" onChange={(e) => handleClipboardDataChange(e, "postalCode")} fullWidth helperText={false} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextInput source="city" validate={required()} label="Stadt" onChange={(e) => handleClipboardDataChange(e, "city")} fullWidth size="large" helperText={false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="specialRequests" label="Spezielle Kundenwünsche" validate={required()} multiline rows={3} size="large" helperText={false} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="notes" label="Anmerkung" validate={required()} multiline rows={3} size="large" fullWidth helperText={false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput source="constructionSite" defaultValue="" validate={required()} label="Baustelle" size="large" helperText={false} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ReferenceInput
                                        source="regionId"
                                        reference="regions"
                                        label="Region"
                                        fullWidth
                                        helperText={false}
                                        size="large"
                                        // validate={required()}
                                    >
                                        <AutocompleteInput optionText="name" label="Region" validate={required()} />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ReferenceInput source="account_manager_id" label="Kundenbetreuer" reference="users">
                                        <AutocompleteInput optionText="name" validate={required()} label="Kundenbetreuer" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} md={4} marginTop={"0px"}>
                                    <DateInput source="customerSince" label="Kunde seit" fullWidth validate={required()} size="large" helperText={false} />
                                </Grid>
                            </Grid>
                            {contacts.map((contact, index) => (
                                <ContactPersonSection key={index} index={index} contact={contact} />
                            ))}
                        </CardContent>
                    </Card>
                </SimpleForm>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
                <Grid item xs={12} md={7} sm={12}>
                    <Paper
                        elevation={0}
                        sx={{
                            backgroundColor: "#EFF4FA",
                            borderRadius: "6px",
                            p: 2,
                            marginTop: "90px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "left",
                            [theme.breakpoints.down("sm")]: {
                                p: 4, // Adjust margin for mobile
                                marginBottom: "150px",
                                maxHeight: "calc(100vh - 50px)", // Dynamically adjust for screen size
                                height: "100%",
                            },
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "top",
                            }}
                        >
                            <Typography variant="p" sx={{ mb: 1, fontSize: "1rem", fontWeight: "600" }}>
                                Kontaktdaten
                            </Typography>
                            <IconButton aria-label="delete" size="small" sx={{ marginTop: "-5px", color: "#3d3d3d", p: 1 }} onClick={copyToClipboard}>
                                <ContentCopyRoundedIcon fontSize="small"> </ContentCopyRoundedIcon>
                            </IconButton>
                        </div>
                        <div
                            style={{
                                marginTop: "5px",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="p"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                {clipboardData.name}
                                <br></br>
                                {clipboardData.street}
                                <br></br>
                                {clipboardData.postalCode + " " + clipboardData.city}
                                <br></br>
                                {contPersonClipboardData.map((data, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", marginTop: "18px" }}>
                                            <span
                                                style={{
                                                    display: "block",
                                                    textTransform: "capitalize",
                                                    marginRight: "4px",
                                                }}
                                            >
                                                {contPersonClipboardData.length > 1 && index + 1 + ")"} {data.salutation}{" "}
                                            </span>
                                            <div>
                                                {data.firstName} {data.lastName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CustomerForm;
