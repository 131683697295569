import * as React from "react";
import { Datagrid, List, TextField, EditButton, Edit, SimpleForm, TextInput, Create, required, ListButton, TopToolbar, ReferenceField, ReferenceInput, usePermissions, Pagination, DateInput, DateField, Toolbar, SaveButton, DeleteButton, AutocompleteInput } from "react-admin";
import CustomerForm from "./CustomerPage/CustomerForm";
import { ContactProvider } from "./CustomerPage/ContactContext";

const ProjectEditActions = ({ basePath, data }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="< Zurück" />
        </TopToolbar>
    );
};

const ProjectPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />;

export const KundenlisteList = () => {
    const { permissions } = usePermissions();
    return (
        <List filters={projectFilters} perPage={50} pagination={<ProjectPagination />}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="company" />
                <ReferenceField source="regionId" reference="regions" label="Region" />
                <DateField source="customerSince" label="Kunde Seit" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const KundenlisteEditToolbar = (props) => {
    const { permissions } = usePermissions();
    return (
        <Toolbar {...props}>
            <SaveButton />
            {permissions.access === "super" ? <DeleteButton /> : null}
        </Toolbar>
    );
};

export const KundenlisteEdit = (props) => (
    <ContactProvider>
        <CustomerForm isEditing={true} title="Edit Kundenliste" actions={<ProjectEditActions />} toolbar={<KundenlisteEditToolbar />} />
    </ContactProvider>
);
export const KundenlisteCreate = (props) => (
    <ContactProvider>
        <CustomerForm isEditing={false} title="Add New Kundenliste" props={props} actions={<ProjectEditActions />} />
    </ContactProvider>
);

const projectFilters = [<TextInput label="Search" source="q" alwaysOn />, <ReferenceInput source="regionId" label="Region" reference="regions" alwaysOn />];
