import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress } from "@mui/material";
import config from "../../config";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const RevenuePerCustomerDataGrid = ({ filter }) => {
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModel, setSortModel] = useState([{ field: "revenue_360_days", sort: "desc" }]);

    useEffect(() => {
        const updateData = async () => {
            try {
                setIsLoading(true);
                let limit = [];
                limit.push(paginationModel.page * paginationModel.pageSize);
                limit.push(paginationModel.pageSize);
                let url = config.apiUrl + `?section=dashboardCards&action=revenuePerCustomer&filter=` + encodeURIComponent(JSON.stringify(filter)) + `&range=` + encodeURIComponent(JSON.stringify(limit));
                if (sortModel.length > 0) {
                    url += "&sort=" + encodeURIComponent(JSON.stringify([sortModel[0].field, sortModel[0].sort]));
                }
                const response = await fetch(url);
                const updateData = await response.json();
                const processedRows = updateData.data.map((row, index) => ({
                    ...row,
                    id: Number(row.id) || index,
                }));
                setData(processedRows);
                setRowCount(updateData.total);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    }, [paginationModel, filter, sortModel]);

    // Define columns with sorting and formatting
    const columns = [
        {
            field: "customer_name",
            flex: 1,
            headerName: "Kunde",
            description: "Customer",
            headerClassName: "super-app-theme--header",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "revenue_360_days",
            headerName: "Durchn. Umsatz letzte 360 Tage",
            flex: 1,
            description: "",
            headerClassName: "super-app-theme--header",
            sortable: true,
            valueFormatter: (params) => `${parseFloat(params || 0).toFixed(2)}`,
            disableColumnMenu: true,
        },
        {
            field: "revenue_180_days_per_month",
            headerName: "Durchn. Umsatz letzte 180 Tage ",
            flex: 1,
            headerClassName: "super-app-theme--header",
            sortable: true,
            valueFormatter: (params) => `${parseFloat(params || 0).toFixed(2)}`,
            disableColumnMenu: true,
        },
        {
            field: "revenue_90_days_per_month",
            headerName: "Durchn. Umsatz letzte 90 Tag ",
            flex: 1,

            headerClassName: "super-app-theme--header",
            sortable: true,
            valueFormatter: (params) => {
                return `${parseFloat(params || 0).toFixed(2)}`;
            },
            disableColumnMenu: true,
        },
        {
            field: "revenue_trend_percentage",
            headerName: "Trend (L90vsL180)",
            flex: 1,
            description: "Vergleich des durchschnittlichen Umsatzes der letzten 90 Tage mit dem der letzten 180 Tage",
            headerClassName: "super-app-theme--header",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const value = parseFloat(params.value || 0).toFixed(2);
                let color;
                if (value < 0) {
                    color = "red";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <ArrowDownwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowDownwardIcon>
                            {value}%
                        </span>
                    );
                } else if (value == 0) {
                    color = "rgb(214 177 8)";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginLeft: "2px",
                            }}
                        >
                            <ArrowForwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowForwardIcon>
                            {value}%
                        </span>
                    );
                } else {
                    color = "green";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginLeft: "2px",
                            }}
                        >
                            <ArrowUpwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowUpwardIcon>
                            {value}%
                        </span>
                    );
                }
            },
        },
        {
            field: "trend_e90vsl90",
            headerName: "Trend (E90vsL90)",
            flex: 1,
            description: "Tooltipp with text: Vergleich des durchschnittlichen Umsatzes der ersten 90 Tage mit dem der letzten 90 Tage",
            headerClassName: "super-app-theme--header",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const value = params.value === "-" ? params.value : parseFloat(params.value || 0).toFixed(2);
                let color;
                if (value === "-") {
                    color = "darkgray";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "200",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "1.9rem",
                                marginLeft: "10px",
                            }}
                        >
                            {value}
                        </span>
                    );
                } else if (value == 0) {
                    color = "rgb(214 177 8)";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginLeft: "2px",
                            }}
                        >
                            <ArrowForwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowForwardIcon>
                            {value}%
                        </span>
                    );
                } else if (value < 0) {
                    color = "red";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <ArrowDownwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowDownwardIcon>
                            {value}%
                        </span>
                    );
                } else {
                    color = "green";
                    return (
                        <span
                            style={{
                                color,
                                fontWeight: "light",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginLeft: "2px",
                            }}
                        >
                            <ArrowUpwardIcon fontSize="large" sx={{ fontSize: "17px" }}></ArrowUpwardIcon>
                            {value}%
                        </span>
                    );
                }
            },
        },
    ];
    if (data.length === 0) {
        return <p style={{ textAlign: "center" }}> No Data </p>;
    }
    if (isLoading) {
        return (
            <Box height={300} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <Box sx={{ width: "100%" }}>
                <DataGrid
                    rows={data}
                    rowCount={rowCount}
                    columns={columns}
                    loading={isLoading}
                    pageSizeOptions={[3, 5, 10, 25]}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    density="compact"
                    initialState={{
                        pagination: {
                            paginationModel: paginationModel,
                        },
                    }}
                    sx={{
                        "& .MuiDataGrid-columnHeader": {
                            fontWeight: "bold",
                        },
                        "& .super-app-theme--header": {
                            backgroundColor: "#b6def842",
                        },
                    }}
                />
            </Box>
        );
    }
};

export default RevenuePerCustomerDataGrid;
