import * as React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ReferenceInput,
  ReferenceField,
  NumberInput,
  RecordContextProvider,
  DatagridBody,
  DateField,
  DateInput,
  Pagination,
  ListButton,
  TopToolbar,
  ShowButton,
  DeleteWithConfirmButton,
  AutocompleteInput,
} from "react-admin";
import { TableCell, TableRow, Checkbox, Chip } from "@mui/material";
import config from "../config";

const colorsArr = [
  "#fff",
  "#C80002",
  "#FFD600",
  "#525252",
  "#CCCCCC",
  "#00AEEF",
  "#7925C7",
  "#F75C1E",
  "#92C83E",
  "#F766AE",
];

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => (
  <RecordContextProvider value={record}>
    <TableRow>
      {/* first column: selection checkbox */}
      <TableCell padding="none">
        <Checkbox
          disabled={selectable}
          checked={selected}
          onClick={(event) => onToggleItem(id, event)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={
            typeof field.props.source !== "undefined"
              ? {
                background: colorsArr[record.farbe],
                color:
                  record.farbe == 2 || record.farbe == 4 || record.farbe == 9
                    ? "rgba(0, 0, 0, 0.87)"
                    : "#fff",
                width: field.props.source == "adresse" ? "15%" : "auto",
              }
              : {}
          }
        >
          {field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const ProjectEditActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="< Zurück" />
    </TopToolbar>
  );
};

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const TaskList = () => (
  <List
    filters={projectFilters}
    perPage={20}
    pagination={<ProjectPagination />}
    sort={{ field: "dringlichkeit", order: "ASC" }}
  >
    <MyDatagrid>
      <TextField source="id" />
      <ReferenceField source="workId" reference="works" label="Work List" />
      <TextField source="project_id" label="Tr.-Nr" />
      <TextField source="action" />
      <TextField source="vonwem" label="Von Wem" />
      <TextField source="infos" label="Weitere Infos" />
      <TextField source="adresse" />
      <DateField source="dringlichkeit" />
      <ReferenceField source="zustandig" label="Zuständig" reference="workers">
        <TextField source="name" />
      </ReferenceField>
      <EditButton label="" />
      <DeleteWithConfirmButton label="" />
    </MyDatagrid>
  </List>
);

export const TaskEdit = (props) => (
  <Edit title="Edit Task" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput readOnly source="id" variant="outlined" />
      {/* <TextInput type="hidden" source="id" variant="outlined" /> */}
      <TextInput readOnly source="created_at" variant="outlined" />
      <ReferenceInput
        source="workId"
        reference="works"
        label="Work List"
        // validate={required()}
      />
      <TextInput source="action" />
      <TextInput label="Von Wem" source="vonwem" validate={required()} />
      <TextInput label="Weitere Infos" source="infos" validate={required()} />
      <TextInput label="Adresse" source="adresse" />
      <DateInput source="dringlichkeit" validate={required()} />
      <SelectInput
        source="gewerk"
        label="Gewerk"
        choices={config.gewerkChoices}
      />
      <ReferenceInput source="zustandig" label="Zuständig" reference="workers">
        <AutocompleteInput
          optionText="name"
          validate={required()}
          label="Zuständig"
        />
      </ReferenceInput>
      <ReferenceInput
        source="report_prepared_by_id"
        label="Bericht erstellt von"
        reference="users"
        filter={{ category_id: 2 }}
      >
        <AutocompleteInput
          optionText="name"
          label="Bericht erstellt von"
          // validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        validate={required()}
        source="farbe"
        label="Farbe (color)"
        choices={[
          { id: 1, name: "Red" },
          { id: 2, name: "Yellow" },
          { id: 3, name: "Dark Grey" },
          { id: 4, name: "Lite Grey" },
          { id: 5, name: "Blue" },
          { id: 6, name: "purple" },
          { id: 7, name: "Orange" },
          { id: 8, name: "Green" },
          { id: 9, name: "Pink" },
        ]}
      />
      <TextInput readOnly source="completed_at" variant="outlined" />
    </SimpleForm>
  </Edit>
);

export const TaskCreate = (props) => (
  <Create title="Add New Task" {...props} actions={<ProjectEditActions />}>
    <SimpleForm>
      <ReferenceInput
        source="WorkId"
        reference="works"
        label="Work List"
        // validate={required()}
      />
      <TextInput source="action" />
      <TextInput label="Von Wem" source="vonwem" validate={required()} />
      <TextInput label="Weitere Infos" source="infos" validate={required()} />
      <TextInput label="Adresse" source="adresse" />
      <DateInput source="dringlichkeit" validate={required()} />
      <SelectInput
        source="gewerk"
        label="Gewerk"
        choices={config.gewerkChoices}
      />
      <ReferenceInput source="zustandig" label="Zuständig" reference="workers">
        <AutocompleteInput optionText="name" validate={required()} />
      </ReferenceInput>
      <ReferenceInput
        source="report_prepared_by_id"
        label="Bericht erstellt von"
        reference="users"
        filter={{ category_id: 2 }}
      >
        <AutocompleteInput
          optionText="name"
          label="Bericht erstellt von"
          // validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        validate={required()}
        source="farbe"
        label="Farbe (color)"
        choices={[
          { id: 1, name: "Red" },
          { id: 2, name: "Yellow" },
          { id: 3, name: "Dark Grey" },
          { id: 4, name: "Lite Grey" },
          { id: 5, name: "Blue" },
          { id: 6, name: "purple" },
          { id: 7, name: "Orange" },
          { id: 8, name: "Green" },
          { id: 9, name: "Pink" },
        ]}
      />
    </SimpleForm>
  </Create>
);

const projectFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput
    source="workId"
    label="Work List"
    reference="works"
    alwaysOn
  />,
  <SelectInput
    source="farbe"
    label="Farbe (color)"
    className="list_farbe"
    emptyValue="1"
    emptyText="Red"
    choices={[
      { id: 2, name: "Yellow" },
      { id: 3, name: "Dark Grey" },
      { id: 4, name: "Lite Grey" },
      { id: 5, name: "Blue" },
      { id: 6, name: "purple" },
      { id: 7, name: "Orange" },
      { id: 8, name: "Green" },
      { id: 9, name: "Pink" },
    ]}
    alwaysOn
    resettable
  />,
];
