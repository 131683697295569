export const doFlex = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 0,
};

export const labelChoices = [
  { id: "buro", name: "Büro" },
  { id: "mobil", name: "Mobil" },
  { id: "privat", name: "Privat" },
];

export const titleChoices = [
  { id: "frau", name: "Frau" },
  { id: "herr", name: "Herr" },
];
