import React from "react";
import { required, SelectInput, TextInput } from "react-admin";
import { Grid, IconButton } from "@mui/material";
import { doFlex, labelChoices } from "./styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { useContacts } from "./ContactContext";
import config from "../../config";
import InputAdornment from "@mui/material/InputAdornment";
import CountrySelect from "./CountrySelect";

const apiUrl = config.apiUrl;

const ContactNumberSection = ({ index, phoneNumber, contactPersonIndex }) => {
    const { deletePhoneNumber } = useContacts();
    const validatePhoneNumber = (value, allValues) => {
        const contact = allValues.contacts[contactPersonIndex];
        if (contact.phoneNumbers[index].label && !value) {
            return "Phone number is required when label is selected";
        }

        const currentPhone = contact.phoneNumbers[index];
        if (contact.phoneNumbers.length <= 1 && ((currentPhone.countryCode && currentPhone.countryCode !== "49") || currentPhone.label) && !value) {
            return "Please enter a phone number";
        }

        return undefined;
    };

    const handleDeletePhoneNumber = async (contactPersonIndex, index, phoneNumberId) => {
        deletePhoneNumber(contactPersonIndex, index);
        try {
            const response = await fetch(`${apiUrl}?section=kundenlistes&action=deletePhoneNumber&id=${phoneNumberId}`, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const result = await response.json();
            console.log("Phone number deleted successfully", result);
        } catch (err) {
            console.error("Phone number not deleted", err);
        }
    };

    return (
        <>
            <Grid item xs={12} md={3} style={doFlex} marginTop={"10px"}>
                <CountrySelect source="contacts" contactPersonIndex={contactPersonIndex} phoneNumberIndex={index} defaultValue={"49"} phoneNumber={phoneNumber}></CountrySelect>
            </Grid>
            <Grid item xs={12} md={5} flexGrow={1} style={doFlex} marginTop={"14px"}>
                <TextInput
                    source={`contacts[${contactPersonIndex}].phoneNumbers[${index}].phoneNumber`}
                    label="Telefonnummer"
                    defaultValue={phoneNumber ? phoneNumber.phoneNumber : ""}
                    fullWidth
                    helperText={false}
                    size="large"
                    validate={validatePhoneNumber}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                sx={{
                                    padding: "4px",
                                    borderRadius: "4px",
                                }}
                            >
                                <span>+{phoneNumber.countryCode || "49"}</span>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4} style={doFlex} marginTop={"10px"}>
                <SelectInput source={`contacts[${contactPersonIndex}].phoneNumbers[${index}].label`} choices={labelChoices} label="Label" defaultValue={phoneNumber ? phoneNumber.label : ""} helperText={false} sx={{ marginBottom: "4px" }} fullWidth size="large" />
            </Grid>
            <Grid item xs={12} md={12} style={doFlex} marginTop={"14px"}>
                {index > 0 && (
                    <IconButton onClick={() => handleDeletePhoneNumber(contactPersonIndex, index, phoneNumber.id)} color="error" size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Grid>
        </>
    );
};
export default ContactNumberSection;
