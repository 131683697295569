const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    imgApiUrl: process.env.REACT_APP_IMG_API_URL,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    gewerkChoices: [
        { id: "DE", name: "DE" },
        { id: "DE+TR", name: "DE+TR" },
        { id: "TR", name: "TR" },
        { id: "FM", name: "FM" },
        { id: "LO", name: "LO" },
        { id: "SchU", name: "SchU" },
        { id: "WHST", name: "WHST" },
        { id: "TR+WHST", name: "TR+WHST" },
        { id: "Sonstige", name: "Sonstige" },
        { id: "SchimmelSan", name: "SchimmelSan" },
        { id: "Abpumpen", name: "Abpumpen" },
        { id: "DE+TR+WHST", name: "DE+TR+WHST" },
        { id: "Rekla", name: "Rekla" },
        { id: "Sanitaer", name: "Sanitär" },
    ],
};

export default config;
