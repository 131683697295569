import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)({
  borderRadius: "20px",
  padding: "8px 20px",
  backgroundColor: "rgb(33 150 243 / 10%)",
  fontWeight: "bolder",
  textDecoration: "none",
  textTransform: "none",
  color: "#1976d2",
  "&:hover": {
    backgroundColor: "rgb(33 150 243 / 20%)",
  },
});
