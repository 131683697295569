import React, { createContext, useContext, useState } from "react";

const ContactContext = createContext();

export const useContacts = () => {
    return useContext(ContactContext);
};

export const ContactProvider = ({ children }) => {
    const [contacts, setContacts] = useState([
        {
            emails: [""],
            phoneNumbers: [
                {
                    label: "",
                    countryCode: "",
                    phoneNumber: "",
                    flagUrl: "https://flagcdn.com/w20/de.png",
                    flagName: "Germany",
                    countryCodeLabel: "DE",
                },
            ],
            salutation: "",
            firstName: "",
            lastName: "",
        },
    ]);
    const [copySuccess, setCopySuccess] = useState("");
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const addContact = () => {
        setContacts([
            ...contacts,
            {
                emails: [""],
                phoneNumbers: [
                    {
                        label: "",
                        countryCode: "",
                        phoneNumber: "",
                        flagUrl: "https://flagcdn.com/w20/de.png",
                        flagName: "Germany",
                        countryCodeLabel: "DE",
                    },
                ],
                salutation: "",
                firstName: "",
                lastName: "",
            },
        ]);
    };
    const addContactNumber = (contactIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          phoneNumbers: [
                              ...contact.phoneNumbers,
                              {
                                  label: "",
                                  countryCode: "",
                                  phoneNumber: "",
                                  flagUrl: "https://flagcdn.com/w20/de.png",
                                  flagName: "Germany",
                                  countryCodeLabel: "DE",
                              },
                          ],
                      }
                    : contact
            )
        );
    };
    const addContactEmail = (contactIndex) => {
        setContacts((prevContacts) => {
            return prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          emails: [...contact.emails, ""],
                      }
                    : contact
            );
        });
    };

    const updateContactField = (index, field, value) => {
        setContacts((prevContacts) => prevContacts.map((contact, i) => (i === index ? { ...contact, [field]: value } : contact)));
    };
    const deleteContact = (index) => {
        if (contacts.length > 1) {
            const updatedContacts = contacts.filter((_, i) => i !== index);
            setContacts(updatedContacts);
        }
    };

    const deletePhoneNumber = (contactIndex, phoneNumberIndex) => {
        const updatedContacts = [...contacts];
        if (updatedContacts[contactIndex].phoneNumbers.length > 1) {
            updatedContacts[contactIndex].phoneNumbers = updatedContacts[contactIndex].phoneNumbers.filter((_, index) => index !== phoneNumberIndex);
            setContacts(updatedContacts);
        }
    };

    const deleteEmail = (contactIndex, emailIndex) => {
        const updatedContacts = [...contacts];
        if (updatedContacts[contactIndex].emails.length > 1) {
            updatedContacts[contactIndex].emails = updatedContacts[contactIndex].emails.filter((_, index) => index !== emailIndex);
            setContacts(updatedContacts);
        }
    };

    return (
        <ContactContext.Provider
            value={{
                contacts,
                setContacts,
                addContact,
                addContactNumber,
                addContactEmail,
                updateContactField,
                deleteContact,
                deletePhoneNumber,
                deleteEmail,
                copySuccess,
                setCopySuccess,
                isSnackbarOpen,
                setSnackbarOpen,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};
